@import "~bootstrap/scss/functions.scss"
@import "~bootstrap/scss/_variables.scss"
@import "~bootstrap/scss/bootstrap.scss"

$max-icon-width: 36
@mixin icon-action-td-size ($count)
  min-width: #{$max-icon-width * $count}px

.icons-logo
  background-image: url('../../public/5c0635f414e32a00234c30a0_optimized_118.png')
  background-repeat: no-repeat


.navbar-brand
  background-position: 15px 0

.dashboard-chart
  height: 250px


.logo-50
  width: 105px
  height: 72px
  display: inline-block
  background-size: auto 72px

.zend-form
  input[type="checkbox"]
    margin: 0 12px 0 0
  fieldset
    @extend .col-12
  ul
    li
      @extend .col-12
      @extend .text-danger
  textarea
    height: 150px

label
  @extend .text-info

.ellipsis
  text-overflow: ellipsis
  max-width: 100%
  overflow: hidden
  white-space: nowrap
  position: absolute


.td-fluid
  overflow: hidden
  position: relative
  height: 28px
  display: inline-block
  float: left
  width: 100%


.table-full-date
  min-width: 165px


.table-ip
  min-width: 110px

.table-egroup-extra
  min-width: 160px


.table-risk
  min-width: 58px

@for $i from 1 through 8
  .table-actions-#{$i}
    @include icon-action-td-size($i)

.table-fluid
  width: 100%


.no-border
  border: 0


.table tr:first-child .td-fluid
  border-top: 0


.bracketed::before
  content: "("


.bracketed::after
  content: ")"


.fa-none:before
  display: none


.metrics-container
  height: 400px


.metrics-container .fa-spinner
  font-size: 50px


.payload
  white-space: pre
  color: white
  background: black
  padding: 0 20px
  font-family: monospace



.progress-bar > .text-danger
  position: absolute
  left: 50%
  margin-left: -25%


.sortable
  cursor: pointer

#modal-tree
  max-height : 400px
  overflow : auto

.float-left
  float : left

.float-right
  float : right

.container
  .dataTables_wrapper
    .dataTables_paginate
      .paginate_button, .paginate_button:hover
        color: $primary !important
      .paginate_button:hover
        background: #fff !important
        border: 1px solid $primary
      .paginate_button.current, .paginate_button.current:hover
        border: 1px solid $primary
        background: $primary !important
        color: #fff !important

.child-table
  padding: 0 !important
  > td
    padding: 0

.fa-caret-right, .fa-caret-down
  cursor: pointer

table.dataTable.no-footer
  border-bottom: none

.alert:empty, .logs-datatable
  nav > :not(:last-child), #pagination-first-page, #pagination-last-page
    display: none

.logs-datatable
  white-space: break-spaces

.files-container >.row
  padding: 10px 0
  &:nth-of-type(odd)
    background-color: rgba(159, 205, 255, 0.2)
  &:nth-of-type(even)
    background: #FFFFFF

.you::after
    content: 'you'
    background-color: $success
    color: white
    border-radius: 5px
    margin-left: 0.25rem
    padding: 0.2rem

.close
    margin: calc(var(--bs-modal-header-padding-y)*-.5) calc(var(--bs-modal-header-padding-x)*-.5) calc(var(--bs-modal-header-padding-y)*-.5) auto
    padding: calc(var(--bs-modal-header-padding-y)*.5) calc(var(--bs-modal-header-padding-x)*.5)
    background: transparent url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E") 50%/1em auto no-repeat
    border: 0
    border-radius: .375rem
    box-sizing: initial
    color: #000
    height: 1em
    opacity: .5
    width: 1em

.close span
    display: none

.rv-xy-plot
    position: relative


@media (min-width: 768px)
  #content
    min-height: 500px
